<template>
  <base-section id="404">
    <base-heading title="404" />

    <base-subheading
      class="primary--text"
      title="Ooops!"
    />

    <base-subheading
      space="8"
      title="Parece que essa página não existe"
    />

    <div class="text-center">
      <base-btn :to="{ name: 'Home' }">
        Voltar
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
